import React, { useEffect, useState, useCallback } from "react";
import { Line, Pie, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from "chart.js";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { ActionDashboard } from "../../store/actions/actions-server-data";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);
const Dashboard = () => {
  const dispatch = useDispatch();
  const { rServerData: ServerData, rLoading: isLoading } = useSelector(
    (state) => state
  );
  const userAccessToken = ServerData?.userAccessToken;
  const dashboardData = ServerData?.dashboard?.data || {};
  const [selectedCountry, setSelectedCountry] = useState(() => {
    const firstNonNullCountry = dashboardData?.locationData?.find(
      (loc) => loc.country !== null
    )?.country || "";
    
    return firstNonNullCountry;
  });
  const [paymentPeriod, setPaymentPeriod] = useState("Monthly");
  const [completionPeriod, setCompletionPeriod] = useState("Month");
  const [registering, setRegistering] = useState("month");
 
  const [loading, setLoading] = useState(true);

  const fetchDashboardData = useCallback(async () => {
    if (!userAccessToken) {
      NotificationManager.error("User access token is missing", "Error");
      setLoading(false);
      return;
    }

    try {
      await dispatch(ActionDashboard({ token: userAccessToken }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      NotificationManager.error("Failed to fetch dashboard data", "Error");
    } finally {
      setLoading(false);
    }
  }, [dispatch, userAccessToken]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const monthColors = [
    "rgb(76, 175, 80)",
    "rgb(255, 87, 34)",
    "rgb(255, 235, 59)",
    "rgb(0, 188, 212)",
    "rgb(139, 195, 74)",
    "rgb(255, 152, 0)",
    "rgb(255, 235, 59)",
    "rgb(129, 199, 132)",
    "rgb(33, 150, 243)",
    "rgb(25, 118, 210)",
    "rgb(124, 179, 66)",
    "rgb(183, 28, 28)",
  ];

  const weekColors = [
    "rgb(255, 99, 132)",
    "rgb(54, 162, 235)",
    "rgb(255, 206, 86)",
    "rgb(75, 192, 192)",
    "rgb(153, 102, 255)",
    "rgb(255, 159, 64)",
    "rgb(199, 199, 199)",
  ];

  let colorIndex = 0;

  const getUniqueColor = () => {
    const hue = (colorIndex * 137.5) % 360;
  colorIndex++;
  return `hsl(${hue}, 100%, 50%)`;
  };

  const filteredLocationData = dashboardData?.locationData?.filter(
    (loc) => loc.country === selectedCountry
  );

  const locationData = {
    labels:
      filteredLocationData?.map(
        (loc) =>
          `${loc.state !== null ? `${loc.state} -` : "Others"} ${
            loc.country !== null ? loc.country : ""
          }`
      ) || [],
    datasets: [
      {
        data: filteredLocationData?.map((loc) => loc.count) || [],
        backgroundColor:
          filteredLocationData?.map((loc) =>
            loc.statecolor === "#FFFFFF" ? getUniqueColor() : loc.statecolor
          ) || [],
      },
    ],
  };

  const uniqueCountries = [];

  dashboardData?.locationData?.forEach((item, idx) => {
    if (
      item.country &&
      !uniqueCountries.some((el) => el.country === item.country)
    ) {
      uniqueCountries.push({ country: item.country, idx: idx });
    }
  });


  useEffect(() => {
    if (dashboardData?.locationData) {
      const defaultCountry =
      dashboardData?.locationData?.find((loc) => loc.country === "United States")?.country ||
      dashboardData?.locationData?.find((loc) => loc.country !== null)?.country ||
      "";
  
      
      setSelectedCountry(defaultCountry);
    }
  }, [dashboardData]); 
  const getAvgValue = (data, index, fallback = 100) => {
    return data?.[index] !== 0 ? data?.[index] : fallback;
  };

  const AVG_Reg_1 = getAvgValue(dashboardData?.AVG_Registrations, 0);
  const AVG_Reg_2 = getAvgValue(dashboardData?.AVG_Registrations, 1);
  const AVG_Pur_1 = getAvgValue(dashboardData?.AVG_Purchases, 0);
  const AVG_Pur_2 = getAvgValue(dashboardData?.AVG_Purchases, 1);

  const registrationData = {
    labels: ["Registration", "Course Purchase"],
    datasets: [
      {
        data: [
          Math.round(registering === "month" ? AVG_Reg_1 : AVG_Reg_2),
          Math.round(registering === "month" ? AVG_Pur_1 : AVG_Pur_2),
        ],
        backgroundColor: ["#36A2EB", "#FFCE56"],
      },
    ],
  };

  const paymentArray =
    paymentPeriod === "Monthly"
      ? dashboardData.MonthpaymentData
      : Object.values(dashboardData.WeekpaymentData);

  const paymentData = {
    labels: paymentArray?.map((item) => item.month || item.day) || [],
    datasets: [
      {
        label: "$995 MainCourse",
        data: paymentArray?.map((item) => item.countof995) || [],
        borderColor: "#DCD853",
        fill: false,
        backgroundColor: "#DCD853",
      },
      {
        label: "$500 Subscription",
        data: paymentArray?.map((item) => item.countof500) || [],
        borderColor: "cyan",
        fill: false,
        backgroundColor: "cyan",
      },
      {
        label: "$250 Subscription",
        data: paymentArray?.map((item) => item.countof250) || [],
        borderColor: "purple",
        fill: false,
        backgroundColor: "purple",
      },
    ],
  };

  const completionsArray =
    completionPeriod === "Month"
      ? dashboardData.MonthcompletionData
      : dashboardData.WeekcompletionData;
  const completionData = {
    labels: completionsArray?.map((item) => item.month || item.day) || [],
    datasets: [
      {
        label: "Completions",
        data:
          completionsArray?.map(
            (item) => item.completions || item.weeklyCompletions
          ) || [],
        backgroundColor:
          completionPeriod === "Month" ? monthColors : weekColors,
      },
    ],
  };

  const paymentOptions = {
    responsive: true,
    scales: {
      x: { grid: { display: false } },
      y: {
        beginAtZero: true,
        grid: { drawBorder: false },
        ticks: {
          stepSize: 1,
          callback: (value) => (Number.isInteger(value) ? value : null),
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            const tooltipItem = tooltipItems[0];
            if (paymentPeriod === "Monthly") {
              return (
                "Month: " + tooltipItem.label + " " + new Date().getFullYear()
              );
            } else {
              const weekDates = getCurrentWeekDates();
              return "Day: " + weekDates[tooltipItem.dataIndex];
            }
          },
        },
      },
    },
  };

  const completionOptions = {
    responsive: true,
    scales: {
      x: { grid: { display: false } },
      y: { beginAtZero: true, grid: { drawBorder: false } },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => context.dataset.label + ": " + context.raw,
          title: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            if (completionPeriod === "Month") {
              return (
                "Month: " + tooltipItem.label + " " + new Date().getFullYear()
              );
            } else {
              const weekDates = getCurrentWeekDates();
              return "Day: " + weekDates[tooltipItem.dataIndex];
            }
          },
        },
      },
    },
  };

  const registrationDataOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) =>
            context.label +
            ": " +
            (dashboardData?.AVG_Registrations[0] !== 0
              ? context.raw + "%"
              : "0%"),
          title: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            if (registering === "month") {
              return (
                "Month: " +
                tooltipItem.label +
                " " +
                new Date().toLocaleString("default", { month: "long" }) +
                " - " +
                new Date().getFullYear()
              );
            } else {
              return "Week: " + getCurrentWeek();
            }
          },
        },
      },
    },
  };

  const locationsDataOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: (context) => `${context.label}: ${context.raw}`,
          title: (tooltipItems) =>`State: ${tooltipItems[0].label}`,
        },
      },
    },
  };

  const getCurrentWeekDates = () => {
    const today = new Date();
    const sunday = new Date(today.setDate(today.getDate() - today.getDay()));
    return Array.from({ length: 7 }, (_, i) => {
      const date = new Date(sunday);
      date.setDate(sunday.getDate() + i);
      return `${i + 1}. ${date.toLocaleString("en-US", {
        weekday: "short",
      })} ${date.toLocaleString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })}`;
    });
  };

  const getCurrentWeek = () => {
    const today = new Date();
    const sunday = new Date(today.setDate(today.getDate() - today.getDay()));
    const saturday = new Date(sunday);
    saturday.setDate(sunday.getDate() + 6);
    return `${sunday.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
    })} - ${saturday.toLocaleString("en-US", {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    })}`;
  };

  const formatNumber = (num) => {
    if (num >= 1000000) return (num / 1000000).toFixed(1) + "M";
    if (num >= 1000) return (num / 1000).toFixed(1) + "k";
    return num;
  };

  if (isLoading?.dashboard || loading) {
    return (
      <div className="payment_overlay">
        <div className="d-flex align-items-center justify-content-center flex-column">
          <FontAwesomeIcon
            icon={faSpinner}
            className="fa-spin"
            style={{ fontSize: "40px", color: "#000" }}
          />
          <p className="mt-2 payment_loading">Please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <div className="d-flex justify-content-between">
        <div className="container-fluid">
          <div className="dashboard">
            <div className="row">
              <div className="col-lg-5">
                <div className="stats-container">
                  <div>
                    <div className="stat-box">
                      <h2>{formatNumber(dashboardData.totalUsers || 0)}</h2>
                      <p>Total Registered Users</p>
                    </div>
                    <div className="stat-box mt-3">
                      <h2>{formatNumber(dashboardData.totalGifiting || 0)}</h2>
                      <p>Total Gifted</p>
                    </div>
                  </div>
                  <div>
                    <div className="stat-box">
                      <p id="TotalPurchased">Total Course Purchased</p>
                      <div className="d-flex justify-content-center align-items-center">
                        <p id="purchasedAmount">$995 -</p>
                        <h3 className="totalsubscriptions">
                          {formatNumber(
                            dashboardData.totalCoursesPurchased || 0
                          )}
                        </h3>
                      </div>
                    </div>

                    <div className="stat-box mt-3">
                      <p id="TotalPurchased">Total Subscription</p>
                      <div>
                        <div className="d-flex">
                          <p id="purchasedAmount">$500 -</p>
                          <h3 className="totalsubscriptions">
                            {formatNumber(
                              dashboardData.totalSubscription500 || 0
                            )}
                          </h3>
                        </div>
                        <div className="d-flex">
                          <p id="purchasedAmount">$250 -</p>
                          <h3 className="totalsubscriptions">
                            {formatNumber(
                              dashboardData.totalSubscription250 || 0
                            )}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-12 d-flex justify-content-center align-items-center">
                <div className="charts-container">
                  <div className="row">
                    <div className="col-lg-6 col-12 chartDesign">
                      <div className="chart ">
                        <div className="d-flex justify-content-between">
                          <h3 id="cardstitle" className="mb-5">
                            Locations
                          </h3>
                          <select
                            value={selectedCountry}
                            id="ChartFilter"
                            className="ChartFilter"
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            style={{
                              backgroundColor: "#19252E",
                              color: "#ffffff",
                              overflowY: "auto",
                              maxHeight:"150px",
                              display:"block"
                            }}
                          >
                            {uniqueCountries.map((countryObj, idx) => (
                              <option key={idx}  value={countryObj.country}>
                                {countryObj.country}
                              </option>
                            ))}
                          </select>
                        </div>

                        <Pie
                          data={locationData}
                          options={locationsDataOptions}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 chartDesign">
                      <div className="chart">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <h4 id="cardstitle">All Registration</h4>
                          <select
                            value={registering}
                            id="ChartFilter"
                            onChange={(e) => setRegistering(e.target.value)}
                            className=""
                            style={{
                              backgroundColor: "#19252E",
                              color: "#ffffff",
                            }}
                          >
                            <option value="month">Monthly</option>
                            <option value="week">Weekly</option>
                          </select>
                        </div>
                        <div className="mt-5 ailgn-items-center">
                          <Pie
                            className="RegistrationPie"
                            data={registrationData}
                            options={registrationDataOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 chartDesign">
            <div className="chart payment-status w-100">
              <div className="d-flex justify-content-between mb-3">
                <h3 id="cardstitle">Payment Status</h3>
                <select
                  value={paymentPeriod}
                  id="ChartFilter"
                  onChange={(e) => setPaymentPeriod(e.target.value)}
                  style={{ backgroundColor: "#19252E", color: "#ffffff" }}
                >
                  <option value="Monthly">Monthly</option>
                  <option value="Weekly">Weekly</option>
                </select>
              </div>
              <Line data={paymentData} options={paymentOptions} />
            </div>
          </div>

          <div className="col-12 col-lg-6 chartDesign">
            <div className="chart course-completion w-100">
              <div className="d-flex justify-content-between mb-3">
                <h3 id="cardstitle">Course Completion Status</h3>
                <select
                  value={completionPeriod}
                  id="ChartFilter"
                  onChange={(e) => setCompletionPeriod(e.target.value)}
                  style={{ backgroundColor: "#19252E", color: "#ffffff" }}
                >
                  <option value="Month">Monthly</option>
                  <option value="Week">Weekly</option>
                </select>
              </div>
              <Bar data={completionData} options={completionOptions} />
            </div>
          </div>
        </div>
      </div>

      <NotificationContainer />
    </div>
  );
};

export default Dashboard;
