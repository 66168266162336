import React, { useEffect, useMemo, useState } from "react";
import "./UnlockModules.css";
import {  Button, Col, Row } from "react-bootstrap";
import LockAndUnlockModule from "../../components/lockModules/LockAndUnlockModule";
import { useDispatch, useSelector } from "react-redux";
import { ActionUnlockmodules } from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";
import SiderIcons from "../../components/SVG Icons/Svg";
import { format } from "date-fns";


export default function UnlockModules() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const ServerData = mystate?.rServerData;
  const isLoading = mystate?.rLoading;

  const callAPILoad = isLoading?.orderslistLoading;
  const userAccessToken = ServerData?.userAccessToken || null;
  const UnlockModulesData = useMemo(()=>ServerData?.UnlockModules?.data || [],[ServerData])

  const itemsPerPage = 8;

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [CustomerData, setCustomerData] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [initialData, setInitialData] = useState([]);
  const [fromDatePicker, setFromDatePicker] = useState(null);
  const [toDatePicker, setToDatePicker] = useState(null);

  useEffect(() => {
    dispatch(ActionUnlockmodules({ token: userAccessToken }));
  }, [dispatch, userAccessToken]);

  useEffect(() => {
    setInitialData(UnlockModulesData);
    setFilteredData(UnlockModulesData);
  }, [UnlockModulesData]);

  useEffect(() => {
    if (fromDatePicker && toDatePicker && fromDate) {
      toDatePicker.set("minDate", fromDate);
    }
  }, [fromDate, fromDatePicker, toDatePicker]);

  const timeDateFormate = (timestamp, deviceType) => {
    let date = new Date(timestamp);

    return format(date, "dd MMM yyyy");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let filteredResult = initialData;
  
    if (CustomerData) {
      filteredResult = filteredResult.filter(
        (item) =>
          item.CustomerEmail.toLowerCase().includes(CustomerData.trim().toLowerCase()) ||
          item.userId.toString().includes(CustomerData.trim()) ||
          item.Firstname.toLowerCase().includes(CustomerData.trim().toLowerCase()) ||
          item.Lastname.toLowerCase().includes(CustomerData.trim().toLowerCase()) ||
          `${item.Firstname.toLowerCase()} ${item.Lastname.toLowerCase()}`.includes(CustomerData.trim().toLowerCase())
      );
    }
    if (fromDate) {
      const fromdate = new Date(fromDate);
    
      if (toDate) {
        const todate = new Date(toDate);
        todate.setHours(23, 59, 59, 999);
    
        filteredResult = filteredResult.filter((item) => {
          const itemDate = new Date(item.PurchasedOn);
          return itemDate >= fromdate && itemDate <= todate;
        });
      } else {
        if (toDatePicker && !CustomerData) {
          toDatePicker.open();
        }
      }
    } else if (toDate) {
      if (fromDatePicker && !CustomerData) {
        fromDatePicker.open();
      }
    }
    
  
    setFilteredData(filteredResult);
    setCurrentPage(1);
  };
  

  const handleCustomerDataChange = (e) => {
    setCustomerData(e.target.value);
  };

  const handelResetBtn = () => {
    setCustomerData("");
    setFromDate("");
    setToDate("");
    setFilteredData(UnlockModulesData);
    setCurrentPage(1);
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage)


  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const getPageRange = (currentPage) => {
    const start = Math.floor((currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, totalPages);
    return { start, end };
  };

  return (
    <div>
      {callAPILoad && UnlockModules.length === 0 ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div className="ms-md-3 ms-sm-0">
          <div className="filterOption d-flex">
            <div className="container-fluid">
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col xm={6} sm={6} md={5} lg={6} className="p-0">
                    <div className="me-4">
                      <div className="form-group position-relative">
                        <input
                          type="text"
                          name="CustomerData"
                          className="form-control"
                          id="UnlockMouduleInput"
                          value={CustomerData}
                          onChange={handleCustomerDataChange}
                          placeholder="Search by email ID, name or customer ID"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xm={6} sm={6} md={7} lg={6} className="p-0">
                    <div className="ms-0">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <div className="me-3 position-relative d-flex">
                            <Flatpickr
                              className="form-control"
                              placeholder="Purchase From "
                              options={{
                                dateFormat: "Y-m-d",
                                altFormat: "d M Y",
                                altInput: true,
                                allowInput: true,
                                onReady: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  setFromDatePicker(instance);
                                },
                              }}
                              value={fromDate}
                              onChange={([date]) => setFromDate(date)}
                            />
                          </div>
                          <div className="position-relative me-3">
                            <Flatpickr
                              className="form-control"
                              placeholder="Purchase To"
                              options={{
                                dateFormat: "Y-m-d",
                                altFormat: "d M Y",
                                altInput: true,
                                allowInput: true,
                                onReady: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  setToDatePicker(instance);
                                },
                              }}
                              value={toDate}
                              onChange={([date]) => setToDate(date)}
                            />
                          </div>
                        </div>
                        <div>
                          <button
                            type="submit"
                            id="filterSubmitBtn"
                            className="btn "
                          >
                            Search
                          </button>
                          <button
                            type="submit"
                            className="btn smallScreen"
                            id="filterSubmitIcons"
                          >
                            <SiderIcons icons={8} />
                          </button>
                        </div>
                        <div className="ResetBtn">
                          <button
                            className="btn "
                            id="ResetBtn"
                            onClick={handelResetBtn}
                          >
                            <span id="reset">Reset</span>
                            <span id="reseticon">
                              <i className="fa-solid fa-rotate-right"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
          </div>
          {filteredData.length === 0 ? (
            <div
              className="text-center mt-5"
              style={{ fontSize: "20px", fontWeight: "500" }}
            >
              <p>No Data Found</p>
            </div>
          ) : (
            <div>
              <div
                className="outputTables table text-center mt-4 mb-2"
                style={{ overflowX: "auto" }}
              >
                <table className="col-12">
                  <thead>
                    <tr id="tableHead">
                      <th style={{ whiteSpace: "nowrap" }}>Purchase Date</th>
                      <th style={{ whiteSpace: "nowrap" }}>Name</th>
                      <th style={{ whiteSpace: "nowrap" }}>Email ID</th>
                      <th style={{ whiteSpace: "nowrap" }}>Expire Date</th>
                      <th style={{ whiteSpace: "nowrap" }}>Session Progress</th>
                      <th style={{ whiteSpace: "nowrap" }}>Lecture Progress</th>
                      <th style={{ whiteSpace: "nowrap" }}>Modules</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData.map((item, index) => (
                      <tr key={index} id={"tableData"}>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {timeDateFormate(item.PurchasedOn || "NA")}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.Firstname + " " + item.Lastname || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.CustomerEmail || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {timeDateFormate(item.ExpirationOn || "NA")}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.SessionProgress || "NA"}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {item.LectureProgress || "NA"}
                        </td>
                        <td>
                          <LockAndUnlockModule
                            userIds={item.userId}
                            userName={item.Firstname + " " + item.Lastname}
                            isDisabled={
                              item.SessionProgress === "6" &&
                              item.LectureProgress === "57"
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-center">
                {UnlockModulesData.length > 8 && (
                  <div className="d-flex align-items-center">
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        <i className="fa-solid fa-backward-step"></i> Prev
                      </Button>
                    )}

                    <div className="d-flex text-center align-items-center">
                      {Array.from(
                        {
                          length:
                            getPageRange(currentPage).end -
                            getPageRange(currentPage).start +
                            1,
                        },
                        (_, i) => {
                          const page = getPageRange(currentPage).start + i;

                          return (
                            <Button
                              key={page}
                              variant={
                                page === currentPage ? "primary" : "link"
                              }
                              onClick={() => handlePageChange(page)}
                              disabled={page === currentPage}
                              className={`mx-1 pagination-button ${
                                page === currentPage ? "active" : ""
                              }`}
                              style={{
                                backgroundColor:
                                  totalPages > 1 && page === currentPage
                                    ? "#402F7B"
                                    : "transparent",
                                color: page === currentPage ? "#fff" : "#000",
                                border: "none",
                                textDecoration: "none",
                              }}
                            >
                              {totalPages > 1 && page}
                            </Button>
                          );
                        }
                      )}

                      {/* Ellipsis */}
                      {totalPages >= 10 && currentPage <=  Math.floor(totalPages / 10) * 10 && (
                        <span>...</span>
                      )}

                      {/* Last Page Button */}
                      {totalPages > 10 && (
                        <Button
                          variant="link"
                          onClick={() => handlePageChange(totalPages)}
                          disabled={currentPage === totalPages}
                          className="pagination-button"
                          style={{
                            color: "#000",
                            border: "none",
                            textDecoration: "none",
                          }}
                        >
                          {currentPage <=  Math.floor(totalPages / 10) * 10 ? totalPages : ""}
                        </Button>
                      )}
                    </div>

                    {/* Next Button */}
                    {totalPages > 1 && (
                      <Button
                        variant="link"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="pagination-button"
                        style={{
                          color: "#000",
                          border: "none",
                          textDecoration: "none",
                        }}
                      >
                        Next <i className="fa-solid fa-forward-step"></i>
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
