export const URL_MAP = {
  BASE_URL: "",

  AdminLogin: "/",
  AdminForgotPassword: "adminForgotpassword",
  AdminUpdatepassword: "adminUpdatepassword",
  AdminDashboard: "dashboard",
  AdminUnlockModules: "unlockmodules",
  AdminPaymentStatus: "paymentstatus",
  AdminRegistrationDetails: "RegisterationDetails",
  AdminCourseDetails: "courseDetails",
  AdminCustomerDetails: "customerdetails",
  AdminPaymentStatus_csv:"getPaymentStatus_csv",
  AdminStudentProgress:"studentprogress",
  AdminProductDetails:"Product",
  AdminPaymentLink:"paymentLink",
  AdminSubscription:"subscriptions",
  AuthorizeSubscriptions:"AuthorizeSubscriptions",
};
