import React, { useEffect, useRef, useState } from "react";
import "./StaticLayout.css";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";

export default function StaticLayout({ children }) {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
  useEffect(() => {
    if (headerRef.current) {
      const SideBarHeight = headerRef.current.getBoundingClientRect().height;
      setHeaderHeight(SideBarHeight);
    }
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      <div className="HeaderPage" ref={headerRef}>
        <Header onMenuClick={toggleSidebar} />
      </div>
      <div className="w-100  d-flex position-relative "  style={{ marginTop: "10px"}}>

          <div
            className={`${isSidebarOpen ? "leftSideNavbar open" : "leftSideNavbar closed"}`}
            style={{
              height: `calc(100vh)`,
              overflowY: "hidden",
            }}
          >
            <SideBar />
          </div>
        
        <div className={" RightSideMainpage px-4 py-3"}
         style={{
          height: `calc(100vh)`,
          overflowY: "auto", 
        }}
        >{children}</div>
      </div>
    </div>
  );
}
