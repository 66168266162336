export default {
  ADMINLOGIN: "/adminLogin",
  ADMINFORGOTPASSWORD: "/adminForgotpassword",
  ADMINRESETPASSWORD: "/adminUpdatepassword",
  ADMINDASHBOARD:"/getdashboard",
  ADMINUNLOCKMODULES:"/getOrdersList",
  ADMINPAYMENTSTATUS:"/getAllOrderEntries",
  ADMINREGISTRATIONDETAILS:"/getAllUserList",
  ADMINCOURSEDETAILS:"/trackProgress",
  ADMINSTUDENTPROGRESS:"/getProgressofStudent",
  ADMINLOCKUNLOCKUSER:"/lockUnlockUser",
  ADMINPRODUCTDETAILS:"/getproductdetails",
  ADMINPAYMENT:"/payment",
  ADMINPAYLINK:"/payLink",
};