import React, { useEffect, useState } from 'react';
import { Modal, Button, Spinner, Alert, Badge } from 'react-bootstrap';
import axios from 'axios';
import './AuthorizeSubscriptions.css';

const AuthorizeSubscriptionModal = ({ show, handleClose, subscription }) => {
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSubscriptionDetails = async (id) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('https://dev.onlinetraining.drgarciabiomagnetism.com/api/customer_details', {
        subscriptionId: id,
      }, {
        headers: {
          'Content-Type': 'application/json',
          // Include CSRF token if necessary
          'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
      });

      if (response.data.success) {
        setDetails(response.data.data);
      } else {
        setError(response.data.data.message || 'Failed to fetch subscription data.');
      }
    } catch (err) {
      setError('An error occurred while fetching subscription data.');
      console.error('fetchSubscriptionDetails:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (show && subscription) {
      fetchSubscriptionDetails(subscription.id);
    }
    // Cleanup on close
    if (!show) {
      setDetails(null);
      setError(null);
    }
    // eslint-disable-next-line
  }, [show, subscription]);

  const formatDateCompletely = (dateInput) => {
    if (!dateInput) return 'N/A';
    const date = new Date(dateInput);
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      weekday: 'short',
    };
    return date.toLocaleString('en-US', options).replace(',', ' at').replace(',', ' on');
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      aria-labelledby="subscription-modal-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title id="subscription-modal-title">Subscription Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="text-center my-3">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {error && <Alert variant="danger">{error}</Alert>}

        {details && (
          <div id="modalContent">
            {/* Subscription Details */}
            {details.subscription && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Subscription Details</h5>
                </div>
                <div className="card-body">
                  <p><strong>Name:</strong> {details.subscription.name || 'N/A'}</p>
                  <p><strong>Amount:</strong> ${details.subscription.amount || 'N/A'}</p>
                  <p>
                    <strong>Status:</strong>{' '}
                    <Badge bg={details.subscription.status === 'ACTIVE' ? 'success' : 'warning'}>
                      {details.subscription.status || 'N/A'}
                    </Badge>
                  </p>
                  <p><strong>Create Date:</strong> {formatDateCompletely(details.subscription.createDate)}</p>
                  <p><strong>Start Date:</strong> {formatDateCompletely(details.subscription.paymentSchedule?.startDate)}</p>
                  <p><strong>Total Occurrences:</strong> {details.subscription.paymentSchedule?.totalOccurrences || 'N/A'}</p>
                  <p><strong>Interval:</strong> Every {details.subscription.paymentSchedule?.interval?.length || 'N/A'} {details.subscription.paymentSchedule?.interval?.unit || 'N/A'}</p>
                  <p><strong>Invoice Number:</strong> {details.subscription.order?.invoiceNumber || 'N/A'}</p>
                  <p><strong>Description:</strong> {details.subscription.order?.description || 'N/A'}</p>
                  <p><strong>Payment Profile ID:</strong> {details.subscription.paymentProfile?.customerPaymentProfileId || 'N/A'}</p>
                </div>
              </div>
            )}

            {/* Customer Information */}
            {details.customer && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Customer Information</h5>
                </div>
                <div className="card-body">
                  <p><strong>Profile ID:</strong> {details.customer.profileId || 'N/A'}</p>
                  <p><strong>Merchant Customer ID:</strong> {details.customer.merchantCustomerId || 'N/A'}</p>
                  <p><strong>Description:</strong> {details.customer.description || 'N/A'}</p>
                  <p><strong>Email:</strong> {details.customer.email || 'N/A'}</p>
                </div>
              </div>
            )}

            {/* Payment Profiles */}
            {details.customer && details.customer.paymentProfiles && details.customer.paymentProfiles.length > 0 && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Payment Profiles</h5>
                </div>
                <div className="card-body">
                  {details.customer.paymentProfiles.map((profile, index) => (
                    <div key={index} className="mb-3">
                      <p><strong>Customer Payment Profile ID:</strong> {profile.customerPaymentProfileId || 'N/A'}</p>
                      <p><strong>Billing Information:</strong></p>
                      <ul>
                        <li><strong>First Name:</strong> {profile.billTo?.firstName || 'N/A'}</li>
                        <li><strong>Last Name:</strong> {profile.billTo?.lastName || 'N/A'}</li>
                        <li><strong>Address:</strong> {profile.billTo?.address || 'N/A'}</li>
                        <li><strong>City:</strong> {profile.billTo?.city || 'N/A'}</li>
                        <li><strong>State:</strong> {profile.billTo?.state || 'N/A'}</li>
                        <li><strong>ZIP:</strong> {profile.billTo?.zip || 'N/A'}</li>
                        <li><strong>Country:</strong> {profile.billTo?.country || 'N/A'}</li>
                      </ul>
                      <p><strong>Payment Method:</strong></p>
                      <ul>
                        <li><strong>Card Type:</strong> {profile.payment?.creditCard?.cardType || 'N/A'}</li>
                        <li><strong>Card Number:</strong> {profile.payment?.creditCard?.cardNumber || 'N/A'}</li>
                        <li><strong>Expiration Date:</strong> {profile.payment?.creditCard?.expirationDate || 'N/A'}</li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Transaction History */}
            {details.transactions && details.transactions.length > 0 ? (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Transaction History</h5>
                </div>
                <div className="card-body">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Transaction ID</th>
                        <th>Submit Time (UTC)</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Auth Code</th>
                        <th>Response Reason</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details.transactions.map((tx, index) => (
                        <tr key={index}>
                          <td>{tx.transactionId || tx.transId || 'N/A'}</td>
                          <td>{formatDateCompletely(tx.submitTimeUTC)}</td>
                          <td>{tx.status || tx.transactionStatus || 'N/A'}</td>
                          <td>${tx.amount || tx.settleAmount || 'N/A'}</td>
                          <td>{tx.authCode || 'N/A'}</td>
                          <td>{tx.responseReasonDescription || 'N/A'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Transaction History</h5>
                </div>
                <div className="card-body">
                  <p>No transactions found for this subscription.</p>
                </div>
              </div>
            )}

            {/* Payment Summary */}
            {details.payment_summary && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Payment Summary</h5>
                </div>
                <div className="card-body">
                  <p><strong>Total Transactions:</strong> {details.payment_summary.total_transactions || 0}</p>
                  <p><strong>Successful Payments:</strong> {details.payment_summary.successful_payments || 0}</p>
                  <p><strong>Failed Payments:</strong> {details.payment_summary.failed_payments || 0}</p>
                  <p><strong>Declined Payments:</strong> {details.payment_summary.declined_payments || 0}</p>
                  <p><strong>Fraud Flagged Payments:</strong> {details.payment_summary.fraud_flagged_payments || 0}</p>
                  <p><strong>Total Amount Collected:</strong> ${details.payment_summary.total_amount_collected || 0}</p>
                  <p><strong>Success Rate:</strong> {details.payment_summary.success_rate || 0}%</p>
                </div>
              </div>
            )}

            {/* Risk Profile */}
            {details.risk_profile && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Risk Profile</h5>
                </div>
                <div className="card-body">
                  <p><strong>Overall Risk Score:</strong> {details.risk_profile.overall_risk_score || 'N/A'}</p>
                  <p><strong>Risk Level:</strong> {details.risk_profile.risk_level || 'N/A'}</p>

                  {/* Risk Factors */}
                  <p><strong>Risk Factors:</strong></p>
                  {details.risk_profile.risk_factors && details.risk_profile.risk_factors.length > 0 ? (
                    <ul>
                      {details.risk_profile.risk_factors.map((factor, idx) => (
                        <li key={idx}>{factor}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Fraud Indicators */}
                  <p><strong>Fraud Indicators:</strong></p>
                  {details.risk_profile.fraud_indicators && details.risk_profile.fraud_indicators.length > 0 ? (
                    <ul>
                      {details.risk_profile.fraud_indicators.map((indicator, idx) => (
                        <li key={idx}>{indicator}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Velocity Checks */}
                  <p><strong>Velocity Checks:</strong></p>
                  {details.risk_profile.velocity_checks && details.risk_profile.velocity_checks.length > 0 ? (
                    <ul>
                      {details.risk_profile.velocity_checks.map((check, idx) => (
                        <li key={idx}>{check}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Suspicious Patterns */}
                  <p><strong>Suspicious Patterns:</strong></p>
                  {details.risk_profile.suspicious_patterns && details.risk_profile.suspicious_patterns.length > 0 ? (
                    <ul>
                      {details.risk_profile.suspicious_patterns.map((pattern, idx) => (
                        <li key={idx}>{pattern}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Recommendations */}
                  <p><strong>Recommendations:</strong></p>
                  {details.risk_profile.recommendations && details.risk_profile.recommendations.length > 0 ? (
                    <ul>
                      {details.risk_profile.recommendations.map((rec, idx) => (
                        <li key={idx}>{rec}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
            )}

            {/* Payment Trends */}
            {details.payment_trends && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Payment Trends</h5>
                </div>
                <div className="card-body">
                  {Object.keys(details.payment_trends).map((trendKey, idx) => {
                    const trendData = details.payment_trends[trendKey];
                    return (
                      <div key={idx} className="mb-3">
                        <p><strong>{toTitleCase(trendKey.replace('_', ' '))}:</strong></p>
                        {Array.isArray(trendData) && trendData.length > 0 ? (
                          <ul>
                            {trendData.map((item, index) => (
                              <li key={index}>{item}</li>
                            ))}
                          </ul>
                        ) : (
                          <p>N/A</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/* Subscription Health */}
            {details.subscription_health && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Subscription Health</h5>
                </div>
                <div className="card-body">
                  <p><strong>Overall Score:</strong> {details.subscription_health.overall_score || 'N/A'}</p>
                  <p><strong>Payment Reliability:</strong> {details.subscription_health.payment_reliability || 'N/A'}</p>
                  <p><strong>Financial Stability:</strong> {details.subscription_health.financial_stability || 'N/A'}</p>
                  <p><strong>Risk Level:</strong> {details.subscription_health.risk_level || 'N/A'}</p>

                  {/* Warning Signs */}
                  <p><strong>Warning Signs:</strong></p>
                  {details.subscription_health.warning_signs && details.subscription_health.warning_signs.length > 0 ? (
                    <ul>
                      {details.subscription_health.warning_signs.map((sign, idx) => (
                        <li key={idx}>{sign}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Positive Indicators */}
                  <p><strong>Positive Indicators:</strong></p>
                  {details.subscription_health.positive_indicators && details.subscription_health.positive_indicators.length > 0 ? (
                    <ul>
                      {details.subscription_health.positive_indicators.map((indicator, idx) => (
                        <li key={idx}>{indicator}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Health Trends */}
                  <p><strong>Health Trends:</strong></p>
                  {details.subscription_health.health_trends && details.subscription_health.health_trends.length > 0 ? (
                    <ul>
                      {details.subscription_health.health_trends.map((trend, idx) => (
                        <li key={idx}>{trend}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}

                  {/* Recommendations */}
                  <p><strong>Recommendations:</strong></p>
                  {details.subscription_health.recommendations && details.subscription_health.recommendations.length > 0 ? (
                    <ul>
                      {details.subscription_health.recommendations.map((rec, idx) => (
                        <li key={idx}>{rec}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
            )}

            {/* Summary */}
            {details.summary && (
              <div className="card mb-4">
                <div className="card-header">
                  <h5>Summary</h5>
                </div>
                <div className="card-body">
                  {/* Subscription Status */}
                  {details.summary.subscription_status && (
                    <>
                      <h6>Subscription Status:</h6>
                      <ul>
                        <li><strong>Name:</strong> {details.summary.subscription_status.subscription_name || 'N/A'}</li>
                        <li><strong>Start Date:</strong> {formatDateCompletely(details.summary.subscription_status.start_date)}</li>
                        <li><strong>Total Occurrences:</strong> {details.summary.subscription_status.total_occurrences || 'N/A'}</li>
                        <li><strong>Amount:</strong> ${details.summary.subscription_status.amount || 'N/A'}</li>
                      </ul>
                    </>
                  )}

                  {/* Recent Activity */}
                  {details.summary.recent_activity && (
                    <>
                      <h6>Recent Activity:</h6>
                      <ul>
                        <li><strong>Last Payment Date:</strong> {details.summary.recent_activity.last_payment_date ? formatDateCompletely(details.summary.recent_activity.last_payment_date) : 'N/A'}</li>
                        <li><strong>Last Payment Status:</strong> {details.summary.recent_activity.last_payment_status || 'N/A'}</li>
                        <li><strong>Recent Issues:</strong> {details.summary.recent_activity.recent_issues && details.summary.recent_activity.recent_issues.length > 0 ? (
                          <ul>
                            {details.summary.recent_activity.recent_issues.map((issue, idx) => (
                              <li key={idx}>{issue}</li>
                            ))}
                          </ul>
                        ) : 'N/A'}</li>
                      </ul>
                    </>
                  )}

                  {/* Financial Metrics */}
                  {details.summary.financial_metrics && (
                    <>
                      <h6>Financial Metrics:</h6>
                      <ul>
                        <li><strong>Total Revenue:</strong> ${details.summary.financial_metrics.total_revenue || 0}</li>
                        <li><strong>Average Transaction Value:</strong> ${details.summary.financial_metrics.average_transaction_value || 0}</li>
                        <li><strong>Payment Success Rate:</strong> {details.summary.financial_metrics.payment_success_rate || 0}%</li>
                      </ul>
                    </>
                  )}

                  {/* Risk Indicators */}
                  {details.summary.risk_indicators && (
                    <>
                      <h6>Risk Indicators:</h6>
                      <ul>
                        <li><strong>Churn Risk:</strong> {details.summary.risk_indicators.churn_risk || 'N/A'}</li>
                        <li><strong>Payment Risk:</strong> {details.summary.risk_indicators.payment_risk || 'N/A'}</li>
                        <li><strong>Fraud Risk:</strong> {details.summary.risk_indicators.fraud_risk || 'N/A'}</li>
                      </ul>
                    </>
                  )}

                  {/* Recommendations */}
                  {details.summary.recommendations && details.summary.recommendations.length > 0 ? (
                    <>
                      <h6>Recommendations:</h6>
                      <ul>
                        {details.summary.recommendations.map((rec, idx) => (
                          <li key={idx}>{rec}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <p><strong>Recommendations:</strong> N/A</p>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

// Helper function to convert string to Title Case
const toTitleCase = (str) => {
  return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

export default AuthorizeSubscriptionModal;