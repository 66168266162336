import React, { useEffect, useState } from "react";
import "./PaymentLink.css";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionPayLinkOption,
  ActionSendPayment,
} from "../../store/actions/actions-server-data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function PaymentLink() {
  const dispatch = useDispatch();
  const mystate = useSelector((state) => state);
  const isLoading = mystate?.rLoading?.PayLinkOptionLoading;
  const userAccessToken = mystate?.rServerData?.userAccessToken || null;
  const payLinkOptions = mystate?.rServerData?.PayLinkOption?.data || [];
  const paymentStatus = mystate?.rServerData?.payment || {};

  const [selectedPayment, setSelectedPayment] = useState({
    paymentType: "",
    amount: "",
  });
  const [selectedEmail, setSelectedEmail] = useState("");
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);

  useEffect(() => {
    dispatch(ActionPayLinkOption({ token: userAccessToken }));
  }, [dispatch]);

  const handleSelectChange = (event) => {
    const selectValue = event.target.value;
    const selectedOption = payLinkOptions.find(
      (option) => option.name === selectValue
    );

    if (selectedOption) {
      setSelectedPayment({
        paymentType: selectedOption.paymentType,
        amount: selectedOption.amount,
      });
    } else {
      setSelectedPayment({
        paymentType: "",
        amount: "",
      });
    }
  };

  const handleEmailChange = (event) => {
    setSelectedEmail(event.target.value);
  };

  const handleSubmit = () => {
    if (!selectedEmail || !selectedPayment.paymentType) {
      alert("Please enter a valid email and select a payment mode.");
      return;
    }

    const paymentData = {
      email: selectedEmail,
      paymentType: selectedPayment.paymentType,
      amount: selectedPayment.amount,
    };

    dispatch(ActionSendPayment(paymentData));

    setTimeout(() => {
      setShowPaymentStatus(true);
    }, 1000);
  };

  return (
    <div>
      {isLoading ? (
        <div className="payment_overlay">
          <div className="d-flex align-items-center justify-content-center flex-column">
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-spin"
              style={{ fontSize: "40px", color: "#000" }}
            />
            <p className="mt-2 payment_loading">Please wait...</p>
          </div>
        </div>
      ) : (
        <div className="px-1 mx-1 px-lg-5 mx-lg-5 align-items-center text-align-center mt-5">
          <div className="px-5">
            <form>
              <div>
                {/* Show payment status only if showPaymentStatus is true */}
                {showPaymentStatus && (
                  <div
                    className={`mb-3 ${
                      paymentStatus.success
                        ? "paymentstatus"
                        : "paymentstatusfail"
                    }`}
                  >
                    {paymentStatus.message}
                  </div>
                )}
                <div className="PaymentLinkTitle mb-3">Create Payment Link</div>
              </div>
              <div>
                <label htmlFor="email" className="paymentLinkLabel mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control w-100"
                  value={selectedEmail}
                  onChange={handleEmailChange}
                  placeholder="Enter customer email"
                  required
                />
              </div>
              <div className="container mt-4">
                <div className="row p-0">
                  <div className="col-lg-6 ps-lg-0 pe-lg-3 p-sm-0">
                    <label
                      htmlFor="paymentMode"
                      className="paymentLinkLabel mb-2"
                    >
                      Payment Mode
                    </label>
                    <select
                      id="paymentMode"
                      className="form-control mb-4"
                      onChange={handleSelectChange}
                      value={selectedPayment.name}
                    >  
                    <option>
                        Select the Payment option
                    </option>
                      {payLinkOptions.map((option) => (
                        <option key={option.id} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-lg-6 p-sm-0 ps-lg-3 pe-lg-0">
                    <label htmlFor="amount" className="paymentLinkLabel mb-2">
                      Amount
                    </label>
                    <input
                      type="text"
                      id="amount"
                      className="form-control mb-4 amountinput"
                      value={selectedPayment.amount}
                      readOnly
                      disabled
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary px-3 mt-2 paymentBtn"
                onClick={handleSubmit}
              >
                Send Payment Link
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
